/* global Component */
class TemplatePage extends Component {

    static  name() {
        return "templatePage";
    }

    static componentName() {
        return "templatePage";
    }

    getProps() {
        return ["templateCode"];
    }

    getTemplate() {
        return `<div>
                    <headerSectionComponent></headerSectionComponent>
                    <div class="static-page w-100 px-1 px-md-5" :class="[templateCode,classContainer]">
                        <htmlSectionComponent :templateCode="templateCode" ></htmlSectionComponent>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

TemplatePage.registerComponent();